import { pipe } from "fp-ts/lib/function"
import * as A from "fp-ts/Array"
import {
    ChordDegree,
    ChordType,
    DropType,
    Finger,
    Fret,
    Inversion,
} from "../../../types"

export function getInversions(
    chordType: ChordType,
    dropType: DropType
): Inversion[] {
    const inversions = getMajor7Inversions(dropType)

    switch (chordType) {
        case "Dominant 7":
            return pipe(inversions, A.map(flat7th), sort)

        case "Minor 7":
            return pipe(inversions, A.map(flat3rd), A.map(flat7th), sort)

        case "Dominant 7 Flat 5":
            return pipe(inversions, A.map(flat5th), A.map(flat7th), sort)

        case "Major 6":
            return pipe(
                inversions,
                A.map(flat3rd),
                A.map(flat7th),
                A.map(swapTo6thChord),
                sort
            )

        case "Minor 6":
            return pipe(
                inversions,
                A.map(flat3rd),
                A.map(flat5th),
                A.map(flat7th),
                A.map(swapTo6thChord),
                sort
            )

        case "Minor 7 Flat 5":
            return pipe(
                inversions,
                A.map(flat3rd),
                A.map(flat5th),
                A.map(flat7th),
                sort
            )

        case "Diminished 7":
            return pipe(
                inversions,
                A.map(flat3rd),
                A.map(flat5th),
                A.map(flat7th),
                A.map(flat7th),
                sort
            )
    }

    return sort(inversions)
}

function flat3rd(inversion: Inversion): Inversion {
    return flatten(inversion, 3)
}

function flat5th(inversion: Inversion): Inversion {
    return flatten(inversion, 5)
}

function flat7th(inversion: Inversion): Inversion {
    return flatten(inversion, 7)
}

function swapTo6thChord(inversion: Inversion): Inversion {
    const fingers = [
        { ...inversion.finger1 },
        { ...inversion.finger2 },
        { ...inversion.finger3 },
        { ...inversion.finger4 },
    ]

    for (const finger of fingers) {
        if (finger.degree === 3) {
            finger.degree = "R"
        } else if (finger.degree === "R") {
            finger.degree = 6
        } else if (finger.degree === 7) {
            finger.degree = 5
        } else if (finger.degree === 5) {
            finger.degree = 3
        }
    }

    const [finger1, finger2, finger3, finger4] = fingers

    return {
        ...inversion,
        finger1,
        finger2,
        finger3,
        finger4,
    }
}

function flatten(inversion: Inversion, degree: ChordDegree): Inversion {
    return {
        ...inversion,
        finger1: flatFinger(inversion.finger1, degree),
        finger2: flatFinger(inversion.finger2, degree),
        finger3: flatFinger(inversion.finger3, degree),
        finger4: flatFinger(inversion.finger4, degree),
    }
}

function flatFinger(finger: Finger, degree: ChordDegree): Finger {
    if (finger.degree === degree) {
        return {
            ...finger,
            fret: (finger.fret - 1) as Fret,
        }
    }

    return finger
}

function sort(inversions: Inversion[]): Inversion[] {
    const starting6th = inversions.filter(({ root }) => root === "6th")
    const starting5th = inversions.filter(({ root }) => root === "5th")
    const starting4th = inversions.filter(({ root }) => root === "4th")

    return [
        ...sortByRoot(starting6th),
        ...sortByRoot(starting5th),
        ...sortByRoot(starting4th),
    ]
}

function sortByRoot(inversions: Inversion[]): Inversion[] {
    return inversions.sort((a, b) => {
        const rootFingerA = [a.finger1, a.finger2, a.finger3, a.finger4].find(
            (i) => i.degree === "R"
        )

        const rootFingerB = [b.finger1, b.finger2, b.finger3, b.finger4].find(
            (i) => i.degree === "R"
        )

        if ((rootFingerA?.string ?? 0) < (rootFingerB?.string ?? 0)) {
            return -1
        }

        return 1
    })
}

function getMajor7Inversions(dropType: DropType): Inversion[] {
    if (dropType === "Drop 2") {
        return getDrop2()
    } else if (dropType === "Drop 3") {
        return getDrop3()
    } else {
        return getDrop24()
    }
}

function getDrop2(): Inversion[] {
    return [
        ...getDrop2Starting6thString(),
        ...getDrop2Starting5thString(),
        ...getDrop2Starting4thString(),
    ]
}

function getDrop3(): Inversion[] {
    return [...getDrop3Starting6thString(), ...getDrop3Starting5thString()]
}

function getDrop24(): Inversion[] {
    return [...getDrop24Starting6thString(), ...getDrop24Starting5thString()]
}

function getDrop2Starting6thString(): Inversion[] {
    return [
        {
            root: "6th",
            finger1: { fret: 2, string: 1, degree: "R" },
            finger2: { fret: 4, string: 2, degree: 5 },
            finger3: { fret: 3, string: 3, degree: 7 },
            finger4: { fret: 3, string: 4, degree: 3 },
        },
        {
            root: "6th",
            finger1: { fret: 3, string: 1, degree: 5 },
            finger2: { fret: 3, string: 2, degree: "R" },
            finger3: { fret: 2, string: 3, degree: 3 },
            finger4: { fret: 4, string: 4, degree: 7 },
        },
        {
            root: "6th",
            finger1: { fret: 3, string: 1, degree: 3 },
            finger2: { fret: 5, string: 2, degree: 7 },
            finger3: { fret: 1, string: 3, degree: "R" },
            finger4: { fret: 3, string: 4, degree: 5 },
        },
        {
            root: "6th",
            finger1: { fret: 4, string: 1, degree: 7 },
            finger2: { fret: 4, string: 2, degree: 3 },
            finger3: { fret: 2, string: 3, degree: 5 },
            finger4: { fret: 2, string: 4, degree: "R" },
        },
    ]
}

function getDrop2Starting5thString(): Inversion[] {
    return [
        {
            root: "5th",
            finger1: { fret: 2, string: 2, degree: "R" },
            finger2: { fret: 4, string: 3, degree: 5 },
            finger3: { fret: 3, string: 4, degree: 7 },
            finger4: { fret: 4, string: 5, degree: 3 },
        },
        {
            root: "5th",
            finger1: { fret: 3, string: 2, degree: 5 },
            finger2: { fret: 3, string: 3, degree: "R" },
            finger3: { fret: 2, string: 4, degree: 3 },
            finger4: { fret: 5, string: 5, degree: 7 },
        },
        {
            root: "5th",
            finger1: { fret: 3, string: 2, degree: 3 },
            finger2: { fret: 5, string: 3, degree: 7 },
            finger3: { fret: 1, string: 4, degree: "R" },
            finger4: { fret: 4, string: 5, degree: 5 },
        },
        {
            root: "5th",
            finger1: { fret: 4, string: 2, degree: 7 },
            finger2: { fret: 4, string: 3, degree: 3 },
            finger3: { fret: 2, string: 4, degree: 5 },
            finger4: { fret: 3, string: 5, degree: "R" },
        },
    ]
}

function getDrop2Starting4thString(): Inversion[] {
    return [
        {
            root: "4th",
            finger1: { fret: 1, string: 3, degree: "R" },
            finger2: { fret: 3, string: 4, degree: 5 },
            finger3: { fret: 3, string: 5, degree: 7 },
            finger4: { fret: 3, string: 6, degree: 3 },
        },
        {
            root: "4th",
            finger1: { fret: 2, string: 3, degree: 5 },
            finger2: { fret: 2, string: 4, degree: "R" },
            finger3: { fret: 2, string: 5, degree: 3 },
            finger4: { fret: 4, string: 6, degree: 7 },
        },
        {
            root: "4th",
            finger1: { fret: 2, string: 3, degree: 3 },
            finger2: { fret: 4, string: 4, degree: 7 },
            finger3: { fret: 1, string: 5, degree: "R" },
            finger4: { fret: 3, string: 6, degree: 5 },
        },
        {
            root: "4th",
            finger1: { fret: 3, string: 3, degree: 7 },
            finger2: { fret: 3, string: 4, degree: 3 },
            finger3: { fret: 2, string: 5, degree: 5 },
            finger4: { fret: 2, string: 6, degree: "R" },
        },
    ]
}

function getDrop3Starting6thString(): Inversion[] {
    return [
        {
            root: "6th",
            finger1: { fret: 2, string: 1, degree: "R" },
            finger2: { fret: 3, string: 3, degree: 7 },
            finger3: { fret: 3, string: 4, degree: 3 },
            finger4: { fret: 2, string: 5, degree: 5 },
        },
        {
            root: "6th",
            finger1: { fret: 3, string: 1, degree: 5 },
            finger2: { fret: 2, string: 3, degree: 3 },
            finger3: { fret: 4, string: 4, degree: 7 },
            finger4: { fret: 1, string: 5, degree: "R" },
        },
        {
            root: "6th",
            finger1: { fret: 3, string: 1, degree: 3 },
            finger2: { fret: 1, string: 3, degree: "R" },
            finger3: { fret: 3, string: 4, degree: 5 },
            finger4: { fret: 3, string: 5, degree: 7 },
        },
        {
            root: "6th",
            finger1: { fret: 4, string: 1, degree: 7 },
            finger2: { fret: 2, string: 3, degree: 5 },
            finger3: { fret: 2, string: 4, degree: "R" },
            finger4: { fret: 2, string: 5, degree: 3 },
        },
    ]
}

function getDrop3Starting5thString(): Inversion[] {
    return [
        {
            root: "5th",
            finger1: { fret: 2, string: 2, degree: "R" },
            finger2: { fret: 3, string: 4, degree: 7 },
            finger3: { fret: 4, string: 5, degree: 3 },
            finger4: { fret: 2, string: 6, degree: 5 },
        },
        {
            root: "5th",
            finger1: { fret: 3, string: 2, degree: 5 },
            finger2: { fret: 2, string: 4, degree: 3 },
            finger3: { fret: 5, string: 5, degree: 7 },
            finger4: { fret: 1, string: 6, degree: "R" },
        },
        {
            root: "5th",
            finger1: { fret: 3, string: 2, degree: 3 },
            finger2: { fret: 1, string: 4, degree: "R" },
            finger3: { fret: 4, string: 5, degree: 5 },
            finger4: { fret: 3, string: 6, degree: 7 },
        },
        {
            root: "5th",
            finger1: { fret: 4, string: 2, degree: 7 },
            finger2: { fret: 2, string: 4, degree: 5 },
            finger3: { fret: 3, string: 5, degree: "R" },
            finger4: { fret: 2, string: 6, degree: 3 },
        },
    ]
}

function getDrop24Starting6thString(): Inversion[] {
    return [
        {
            root: "6th",
            finger1: { fret: 1, string: 1, degree: "R" },
            finger2: { fret: 3, string: 2, degree: 5 },
            finger3: { fret: 2, string: 4, degree: 3 },
            finger4: { fret: 5, string: 5, degree: 7 },
        },
        {
            root: "6th",
            finger1: { fret: 2, string: 1, degree: 5 },
            finger2: { fret: 2, string: 2, degree: "R" },
            finger3: { fret: 3, string: 4, degree: 7 },
            finger4: { fret: 4, string: 5, degree: 3 },
        },
        {
            root: "6th",
            finger1: { fret: 2, string: 1, degree: 3 },
            finger2: { fret: 4, string: 2, degree: 7 },
            finger3: { fret: 2, string: 4, degree: 5 },
            finger4: { fret: 3, string: 5, degree: "R" },
        },
        {
            root: "6th",
            finger1: { fret: 3, string: 1, degree: 7 },
            finger2: { fret: 3, string: 2, degree: 3 },
            finger3: { fret: 1, string: 4, degree: "R" },
            finger4: { fret: 4, string: 5, degree: 5 },
        },
    ]
}

function getDrop24Starting5thString(): Inversion[] {
    return [
        {
            root: "5th",
            finger1: { fret: 1, string: 2, degree: "R" },
            finger2: { fret: 3, string: 3, degree: 5 },
            finger3: { fret: 3, string: 5, degree: 3 },
            finger4: { fret: 5, string: 6, degree: 7 },
        },
        {
            root: "5th",
            finger1: { fret: 2, string: 2, degree: 5 },
            finger2: { fret: 2, string: 3, degree: "R" },
            finger3: { fret: 4, string: 5, degree: 7 },
            finger4: { fret: 4, string: 6, degree: 3 },
        },
        {
            root: "5th",
            finger1: { fret: 2, string: 2, degree: 3 },
            finger2: { fret: 4, string: 3, degree: 7 },
            finger3: { fret: 3, string: 5, degree: 5 },
            finger4: { fret: 3, string: 6, degree: "R" },
        },
        {
            root: "5th",
            finger1: { fret: 3, string: 2, degree: 7 },
            finger2: { fret: 3, string: 3, degree: 3 },
            finger3: { fret: 2, string: 5, degree: "R" },
            finger4: { fret: 4, string: 6, degree: 5 },
        },
    ]
}
