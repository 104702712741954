import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
`

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    & > div {
        display: flex;
        flex-direction: row;
        gap: 50px;
    }
`
