import styled from "styled-components"
import * as types from "../../../types"

const barHeight = 300
const noteSize = barHeight / 8
const stringSize = 10

export const Bar = styled.div`
    position: relative;
    width: 100%;
    height: ${barHeight}px;
`

// prettier-ignore
export const Note = styled.h3<{
    $string: types.String
    $number: number
    $total: number
}>`
    position: absolute;
    background-color: white;
    height: ${noteSize}px;
    width: ${noteSize}px;
    text-align: center;
    margin-bottom: 0;
    border-radius: 50%;
    box-shadow: 5px 5px 5px rgba(5, 5, 5, 0.157);
    left: ${(props) => ((props.$number + 0.5) / 8.5) * 100}%;
    top: calc(${noteSize * 1.2}px + ${(props) => ((5 - props.$string) / 5) * 100}%);

    animation-name: fadein;
    animation-duration: 0.5s;

    @keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`

export const String = styled.div<{ $string: types.String }>`
    position: absolute;
    background-color: white;
    height: ${stringSize}px;
    width: calc(100% - ${stringSize}px);
    top: ${(props) => ((props.$string - 1) / 5) * 100}%;
    left: ${stringSize}px;
`

export const Divider = styled.div<{ $position: "left" | "right" }>`
    position: absolute;
    height: ${barHeight + stringSize}px;
    width: ${stringSize}px;
    background-color: white;
    left: ${(props) => (props.$position === "left" ? 0 : 100)}%;
    box-shadow: 0 5px 5px rgba(5, 5, 5, 0.527);
`
