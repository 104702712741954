import { Dropdown } from "react-bootstrap"
import * as styles from "./DropDown.styles"

type Props = {
    title: string
    options: string[]
    onClick: (option: string) => void
}

export default function DropDown(props: Props) {
    return (
        <styles.DropDown>
            <Dropdown>
                <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                    {props.title}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {props.options.map((x) => (
                        <Dropdown.Item
                            href="#"
                            key={x}
                            onClick={(x) => {
                                const option = (x.target as any).innerText
                                props.onClick(option)
                            }}
                        >
                            {x}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </styles.DropDown>
    )
}
