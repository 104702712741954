import { Scale } from "../../../types"

/**
 * Chunk up scale into thirds.
 */
export function thirds(scale: Scale): Scale {
    const result: Scale = []

    for (let i = 0; i < scale.length - 4; i++) {
        result.push(scale[i])
        result.push(scale[i + 2])
        result.push(scale[i + 4])
        result.push(scale[i + 2])
    }

    return result
}

/**
 * Chunk up scale into chords.
 */
export function chords(scale: Scale): Scale {
    const result: Scale = []

    for (let i = 0; i < scale.length - 6; i++) {
        result.push(scale[i])
        result.push(scale[i + 2])
        result.push(scale[i + 4])
        result.push(scale[i + 6])
    }

    return result
}
