import * as scales from "./lib/scales"
import * as styles from "./Page.styles"
import { chords, thirds } from "./lib/generator"
import Scale from "./components/Scale"
import DropDown from "../../components/DropDown"
import { useState } from "react"
import { ScalePattern, ScaleType, ScaleVariant, TabNote } from "../../types"

function getScale(
    pattern: ScalePattern,
    type: ScaleType,
    variant: ScaleVariant
): TabNote[] {
    switch (pattern) {
        case "Up and Down": {
            return scales.getScale(type, variant)
        }

        case "Thirds": {
            return thirds(scales.getScale(type, variant))
        }

        case "Chords": {
            return chords(scales.getScale(type, variant))
        }
    }
}

const scaleTypeOptions: ScaleType[] = ["C Major"]
const scaleVariantOptions: ScaleVariant[] = ["String 5 #1", "String 6 #1"]
const scalePatternOptions: ScalePattern[] = ["Up and Down", "Thirds", "Chords"]

export default function Page() {
    const [scaleType, setScaleType] = useState<ScaleType>("C Major")
    const [scaleVariant, setScaleVariant] =
        useState<ScaleVariant>("String 5 #1")
    const [scalePattern, setScalePattern] =
        useState<ScalePattern>("Up and Down")

    return (
        <styles.Container>
            <styles.Header>
                <h1>Scales</h1>
                <div>
                    <DropDown
                        title={scaleType}
                        options={scaleTypeOptions}
                        onClick={(option) => setScaleType(option as ScaleType)}
                    />

                    <DropDown
                        title={scaleVariant}
                        options={scaleVariantOptions}
                        onClick={(option) =>
                            setScaleVariant(option as ScaleVariant)
                        }
                    />

                    <DropDown
                        title={scalePattern}
                        options={scalePatternOptions}
                        onClick={(option) =>
                            setScalePattern(option as ScalePattern)
                        }
                    />
                </div>
            </styles.Header>

            <Scale notes={getScale(scalePattern, scaleType, scaleVariant)} />
        </styles.Container>
    )
}
