import styled from "styled-components"

export const DropDown = styled.div`
    a,
    button {
        font-family: "Permanent Marker", cursive;
    }

    a {
        font-size: 22px;

        @media only screen and (max-width: $mobile-breakpoint) {
            font-size: 17px;
        }
    }

    button {
        font-size: 25px;
        letter-spacing: 5px;
        font-style: italic;

        @media only screen and (max-width: $mobile-breakpoint) {
            font-size: 18px;
        }
    }
`
