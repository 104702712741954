import "bootstrap/dist/css/bootstrap.min.css"
import "./App.scss"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Chords from "./features/chords/Page"
import Scales from "./features/scales/Page"

const router = createBrowserRouter([
    {
        path: "/",
        element: <Chords />,
    },
    {
        path: "/scales",
        element: <Scales />,
    },
])

export default function App() {
    return (
        <div className="App">
            <RouterProvider router={router} />
        </div>
    )
}
