import * as styles from "./Bar.styles"
import { TabNote } from "../../../types"

type Props = {
    notes: TabNote[]
}

export default function Bar(props: Props) {
    return (
        <styles.Bar>
            <styles.Divider $position="left" />
            <styles.String $string={1} />
            <styles.String $string={2} />
            <styles.String $string={3} />
            <styles.String $string={4} />
            <styles.String $string={5} />
            <styles.String $string={6} />
            <styles.Divider $position="right" />
            {props.notes.map((note, index) => (
                <styles.Note
                    $string={note.string}
                    $number={index}
                    $total={props.notes.length}
                >
                    {note.fret}
                </styles.Note>
            ))}
        </styles.Bar>
    )
}
