import { useState } from "react"
import ChordView from "./components/Chord"
import Selector from "./components/Selector"
import { getInversions } from "./lib/inversions"
import { ChordType, DropType, Inversion } from "../../types"

export default function Page() {
    const initialChordType: ChordType = "Major 7"
    const initialDropType: DropType = "Drop 2"
    const [inversions, setInversions] = useState<Inversion[]>(
        getInversions(initialChordType, initialDropType)
    )
    return (
        <>
            <h1>Jazz Guitar Chords</h1>
            <Selector
                onChange={(chordType, dropType) =>
                    setInversions(getInversions(chordType, dropType))
                }
                initialChordType={initialChordType}
                initialDropType={initialDropType}
            />
            <ChordView inversions={inversions} />
        </>
    )
}
