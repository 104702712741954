import "./Chord.scss"
import React from "react"
import { Finger, Inversion } from "../../../types"

const Chord: React.FC<{ inversions: Finger[]; index: number }> = ({
    inversions,
    index,
}) => (
    <div className={`chord inversion-${index}`}>
        <div className="string string-1"></div>
        <div className="string string-2"></div>
        <div className="string string-3"></div>
        <div className="string string-4"></div>
        <div className="string string-5"></div>
        <div className="string string-6"></div>
        <div className="fret fret-1"></div>
        <div className="fret fret-2"></div>
        <div className="fret fret-3"></div>
        <div className="fret fret-4"></div>
        <div className="fret fret-5"></div>
        <div className="fret fret-6"></div>
        {inversions.map(({ fret, string, degree }) => (
            <div
                key={`chord-inversion-${fret}-${string}-${degree}`}
                className={`finger finger-fret-${fret} finger-string-${string}`}
            >
                <h3 className="chord-degree">{degree}</h3>
            </div>
        ))}
        {index % 4 === 0 ? <br /> : <></>}
    </div>
)

const ChordView: React.FC<{
    inversions: Inversion[]
}> = ({ inversions }) => {
    return (
        <div className="chord-view">
            {inversions.map((x, i) => (
                <Chord
                    inversions={[x.finger1, x.finger2, x.finger3, x.finger4]}
                    index={i}
                    key={i}
                />
            ))}
        </div>
    )
}

export default ChordView
