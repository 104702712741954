import "./Selector.scss"
import React, { useState } from "react"
import { ChordType, DropType } from "../../../types"
import DropDown from "../../../components/DropDown"

const Selector: React.FC<{
    onChange: (chordType: ChordType, dropType: DropType) => void
    initialChordType: ChordType
    initialDropType: DropType
}> = (props) => {
    const chordTypes: ChordType[] = [
        "Major 7",
        "Dominant 7",
        "Minor 7",
        "Minor 7 Flat 5",
        "Diminished 7",
        "Dominant 7 Flat 5",
        "Major 6",
        "Minor 6",
    ]
    const dropTypes: DropType[] = ["Drop 2", "Drop 3", "Drop 2/4"]

    const [selectedChordType, setSelectedChordType] = useState<ChordType>(props.initialChordType) // prettier-ignore
    const [selectedDropType, setSelectedDropType] = useState<DropType>(props.initialDropType) // prettier-ignore

    const onChange = (chordType: ChordType, dropType: DropType) => {
        props.onChange(chordType, dropType)
    }

    return (
        <div className="selector">
            <div>
                <DropDown
                    title={selectedChordType}
                    options={chordTypes}
                    onClick={(option) => {
                        setSelectedChordType(option as ChordType)
                        onChange(option as ChordType, selectedDropType)
                    }}
                />
            </div>
            <div>
                <DropDown
                    title={selectedDropType}
                    options={dropTypes}
                    onClick={(option) => {
                        setSelectedDropType(option as DropType)
                        onChange(selectedChordType, option as DropType)
                    }}
                />
            </div>
        </div>
    )
}

export default Selector
