import { Scale, ScaleType, ScaleVariant } from "../../../types"

type ScaleKey = `${ScaleType}_${ScaleVariant}`

const cMajorVariant1: Scale = [
    { fret: 3, string: 2 },
    { fret: 5, string: 2 },
    { fret: 2, string: 3 },
    { fret: 3, string: 3 },
    { fret: 5, string: 3 },
    { fret: 2, string: 4 },
    { fret: 4, string: 4 },
    { fret: 5, string: 4 },
    { fret: 3, string: 5 },
    { fret: 5, string: 5 },
    { fret: 6, string: 5 },
    { fret: 3, string: 6 },
    { fret: 5, string: 6 },
    { fret: 7, string: 6 },
    { fret: 8, string: 6 },
]

const cMajorVariant2: Scale = [
    { fret: 8, string: 1 },
    { fret: 10, string: 1 },
    { fret: 7, string: 2 },
    { fret: 8, string: 2 },
    { fret: 10, string: 2 },
    { fret: 7, string: 3 },
    { fret: 9, string: 3 },
    { fret: 10, string: 3 },
    { fret: 7, string: 4 },
    { fret: 9, string: 4 },
    { fret: 10, string: 4 },
    { fret: 8, string: 5 },
    { fret: 10, string: 5 },
    { fret: 7, string: 6 },
    { fret: 8, string: 6 },
]

const scales: Record<ScaleKey, Scale> = {
    "C Major_String 5 #1": cMajorVariant1,
    "C Major_String 6 #1": cMajorVariant2,
}

export function getScale(type: ScaleType, variant: ScaleVariant): Scale {
    const key: ScaleKey = `${type}_${variant}`
    return scales[key] ?? cMajorVariant1
}
