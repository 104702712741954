import * as styles from "./Scale.styles"
import { chunk } from "lodash"
import Bar from "./Bar"
import { TabNote } from "../../../types"

type Props = {
    notes: TabNote[]
}

export default function Scale(props: Props) {
    return (
        <styles.Container>
            {chunk(props.notes, 8).map((scale) => (
                <Bar notes={scale} />
            ))}
        </styles.Container>
    )
}
